<template>
  <el-card>
    <el-row :gutter="20">
      <el-col :span="4"
        ><el-input
          size="small"
          v-model="queryFilter.keyWorlds"
          placeholder="请输入出纳信息筛选"
          clearable
      /></el-col>

      <el-col :span="4">
        <el-select
          size="small"
          v-model="queryFilter.type"
          clearable
          placeholder="筛选出纳类型"
        >
          <el-option
            v-for="item in cashierTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>

      <el-col :span="4">
        <el-select
          size="small"
          v-model="queryFilter.status"
          clearable
          placeholder="开票类型"
        >
        <el-option
            label="未开票"
            :value="0"
          ></el-option>
          <el-option
            label="已开票"
            :value="1"
          ></el-option>
        </el-select>
      </el-col>

      <el-date-picker
        size="small"
        style="margin: 0 20px"
        v-model="timer"
        type="monthrange"
        align="right"
        unlink-panels
        range-separator="至"
        start-placeholder="开始月份"
        end-placeholder="结束月份"
        :picker-options="pickerOptions"
      >
      </el-date-picker>

      <el-button size="small" type="primary" @click="flashTable"
        >查询出纳信息</el-button
      >

      <el-button size="small" type="success" @click="downExcel"
        >导出出纳表</el-button
      >

      <el-button
        size="small"
        type="success"
        style="float: right"
        @click="addCashierDialogVisible = true"
        >新增出纳信息</el-button
      >
    </el-row>
    <el-row style="margin-top: 20px">
      <el-table
        :data="tableData"
        stripe
        row-key="id"
        style="width: 100%"
        border
      >
        <el-table-column type="index" label="#"> </el-table-column>
        <el-table-column label="出纳摘要" prop="abstract"> </el-table-column>
        <el-table-column label="出纳分类" prop="category"> </el-table-column>
        <el-table-column label="出纳类型">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.cashierDetailType == 0"
              type="success"
              effect="dark"
            >
              {{ scope.row.cashierDetailTypeDisplayName }}
            </el-tag>
            <el-tag
              v-else-if="scope.row.cashierDetailType == 1"
              type="danger"
              effect="dark"
            >
              {{ scope.row.cashierDetailTypeDisplayName }}
            </el-tag>
            <el-tag v-else type="warning" effect="dark">
              {{ scope.row.cashierDetailTypeDisplayName }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="金额" prop="money"> </el-table-column>
        <el-table-column label="是否开票">
          <template slot-scope="scope">
            <el-switch
              :value="scope.row.hasInvoice"
              @change="switchInvoceState(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="time" label="出纳时间">
          <template slot-scope="scope">
            {{ scope.row.time | timeFormat('yyyy-MM-dd') }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="createTime" label="创建时间"> </el-table-column> -->
        <!-- <el-table-column prop="userName" label="创建人员"> </el-table-column> -->
        <el-table-column prop="remark" label="凭证号码"> </el-table-column>
        <el-table-column label="编辑">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-edit"
              @click="openEditCashierDialog(scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="deleteCashier(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row>
      <el-col :span="12">
        <span style="line-height: 30px; font-weight: bold; font-size: 18px">
          当前结余:{{ moneyInfo.nowHaveMoney }}
        </span>
      </el-col>
      <el-col :span="12">
        <el-pagination
          style="margin-top: 20px; text-align: right"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryFilter.pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="queryFilter.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="queryFilter.total"
        >
        </el-pagination>
      </el-col>
    </el-row>

    <el-dialog
      title="新增出纳记录"
      :visible.sync="addCashierDialogVisible"
      @close="addCashierDialogClose"
    >
      <el-form
        ref="addCashierFromRef"
        :model="addCashierForm"
        :rules="addCashierFormRule"
        label-width="100px"
      >
        <el-form-item label="出纳时间" prop="time">
          <el-date-picker
            v-model="addCashierForm.time"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="出纳类型" prop="type">
          <el-select v-model="addCashierForm.cashierDetailType">
            <el-option
              v-for="item in cashierTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型" prop="category">
          <el-select
            v-model="addCashierForm.category"
            filterable
            @focus="onFocus"
            allow-create
            default-first-option
            placeholder="请选择当前出纳资金的用途类型"
          >
            <el-option
              v-for="item in categoryOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="金额" prop="money">
          <el-input v-model="addCashierForm.money"></el-input>
        </el-form-item>
        <el-form-item label="是否开票" prop="hasInvoice">
          <el-switch v-model="addCashierForm.hasInvoice"></el-switch>
        </el-form-item>
        <el-form-item label="凭证号码" prop="remark">
          <el-input v-model="addCashierForm.remark"></el-input>
        </el-form-item>
        <el-form-item label="摘要" prop="abstract">
          <el-input
            type="textarea"
            v-model="addCashierForm.abstract"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="addCashierDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="comfirmAddCashierDialog"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog title="编辑出纳记录" :visible.sync="editCashierDialogVisible">
      <el-form
        ref="eidtCashierFromRef"
        :model="editCashierForm"
        :rules="editCashierFormRule"
        label-width="100px"
      >
        <el-form-item label="出纳时间" prop="time">
          <el-date-picker
            v-model="editCashierForm.time"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="出纳类型" prop="type">
          <el-select v-model="editCashierForm.cashierDetailType">
            <el-option
              v-for="item in cashierTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型" prop="category">
          <el-select
            v-model="editCashierForm.category"
            filterable
            @focus="onFocus"
            allow-create
            default-first-option
            placeholder="请选择当前出纳资金的用途类型"
          >
            <el-option
              v-for="item in categoryOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="金额" prop="money">
          <el-input v-model="editCashierForm.money"></el-input>
        </el-form-item>
        <el-form-item label="是否开票" prop="hasInvoice">
          <el-switch v-model="editCashierForm.hasInvoice"></el-switch>
        </el-form-item>
        <el-form-item label="凭证号码" prop="remark">
          <el-input v-model="editCashierForm.remark"></el-input>
        </el-form-item>
        <el-form-item label="摘要" prop="abstract">
          <el-input
            type="textarea"
            v-model="editCashierForm.abstract"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="editCashierDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmEditCashierDialog"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import { timeOffset } from '@/utils/zoneTime.js'
import {
  getCashierList,
  getCashierTypeOptions,
  addCashier,
  deleteCashier,
  updateCashier,
  downCashierList,
} from '@/api/cashier.js'
import { validateNumber } from '@/utils/validate.js'
import clip from '@/utils/clipboard'
import { iosOnFocus } from '@/utils/iosPlatform.js'
export default {
  data() {
    let validateNumberValid = (rule, value, callback) => {
      if (validateNumber(value)) {
        var number = Number(value)

        if (number < 0) {
          callback(new Error('当前数据不能为负数'))
        } else {
          callback()
        }
      } else {
        callback(new Error('当前类型为非数字类型'))
      }
    }

    return {
      timer: '',
      // 筛选条件
      queryFilter: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
        keyWorlds: '',
        type: null,
        status:null,
      },
      moneyInfo: {
        nowHaveMoney: 0,
      },
      categoryOptions: [
        {
          value: '顺丰',
          label: '顺丰',
        },
        {
          value: '其他普通快递',
          label: '其他普通快递',
        },
        {
          value: '德邦',
          label: '德邦',
        },
        {
          value: '其他日常',
          label: '其他日常',
        },
        {
          value: '快递',
          label: '快递',
        },
        {
          value: '电子元件',
          label: '电子元件',
        },
        {
          value: '日常',
          label: '日常',
        },
        {
          value: '交通',
          label: '交通',
        },
        {
          value: '打印',
          label: '打印',
        },
        {
          value: '包装费用',
          label: '包装费用',
        },
        {
          value: '翻译',
          label: '翻译',
        },
        {
          value: '京东',
          label: '京东',
        },
        {
          value: '餐饮',
          label: '餐饮',
        },
      ],
      tableData: [],
      cashierTypeOptions: [],
      pickerOptions: {
        shortcuts: [
          {
            text: '本月',
            onClick(picker) {
              picker.$emit('pick', [new Date(), new Date()])
            },
          },
          {
            text: '今年至今',
            onClick(picker) {
              const end = new Date()
              const start = new Date(new Date().getFullYear(), 0)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近六个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setMonth(start.getMonth() - 6)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      addCashierDialogVisible: false,
      addCashierForm: {
        time: null,
        cashierDetailType: 1,
        remark: '',
        abstract: '',
        category: '日常',
        hasInvoice: false,
        money: 0,
      },
      addCashierFormRule: {
        time: [{ required: true, message: '请输入出纳时间', trigger: 'blur' }],
        abstract: [
          { required: true, message: '出纳摘要为必选项', trigger: 'blur' },
        ],
        category: [
          { required: true, message: '出纳类型为必填项', trigger: 'blur' },
        ],
        money: [
          {
            required: true,
            message: '出纳金额为必填项',
            trigger: 'blur',
          },
          {
            validator: validateNumberValid,
            trigger: 'blur',
          },
        ],
      },
      editCashierDialogVisible: false,
      editCashierForm: {
        id: null,
        time: null,
        cashierDetailType: 1,
        remark: '',
        abstract: '',
        category: '',
        hasInvoice: false,
        money: 0,
      },
      editCashierFormRule: {
        time: [{ required: true, message: '请输入出纳时间', trigger: 'blur' }],
        abstract: [
          { required: true, message: '出纳摘要为必选项', trigger: 'blur' },
        ],
        category: [
          { required: true, message: '出纳类型为必填项', trigger: 'blur' },
        ],
        money: [
          {
            required: true,
            message: '出纳金额为必填项',
            trigger: 'blur',
          },
          {
            validator: validateNumberValid,
            trigger: 'blur',
          },
        ],
      },
    }
  },
  created() {
    this.getTableData()
    this.getCashierTypeOptions()
  },
  methods: {
    handleCopy(text, event) {
      if (text !== '') {
        clip(text, event)
      }
    },
    async downExcel() {
      let filterData = { ...this.queryFilter }

      if (this.timer) {
        filterData.startTime = timeOffset(this.timer[0])
        filterData.endTime = timeOffset(this.timer[1])
      }

      await downCashierList(filterData)
    },
    async getTableData() {
      let filterData = { ...this.queryFilter }

      if (this.timer) {
        filterData.startTime = timeOffset(this.timer[0])
        filterData.endTime = timeOffset(this.timer[1])
      }

      let { data } = await getCashierList(filterData)

      this.tableData = data.data.map((item) => {
        item.loading = false
        item.detail = null
        return item
      })

      this.moneyInfo.nowHaveMoney = data.otherData

      this.queryFilter.total = data.total
    },
    handleSizeChange(val) {
      this.queryFilter.pageSize = val
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.queryFilter.pageIndex = val
      this.getTableData()
    },
    flashTable() {
      this.queryFilter.pageIndex = 1
      this.getTableData()
    },
    async getCashierTypeOptions() {
      let { data } = await getCashierTypeOptions()

      this.cashierTypeOptions = data
    },
    addCashierDialogClose() {
      this.$refs.addCashierFromRef.resetFields()
    },
    comfirmAddCashierDialog() {
      this.$refs.addCashierFromRef.validate(async (valid) => {
        if (valid) {
          await addCashier({
            ...this.addCashierForm,
            time: timeOffset(this.addCashierForm.time),
          })
          this.getTableData()

          this.addCashierDialogVisible = false
        } else {
          this.$message.error('当前出纳信息尚未填写完整,请补全信息内容')
          return false
        }
      })
    },
    deleteCashier(row) {
      this.$confirm('此操作将会删除当前出纳记录是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          await deleteCashier(row.id)
          this.$message({
            type: 'success',
            message: '删除成功!',
          })

          this.getTableData()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    openEditCashierDialog(row) {
      this.editCashierForm = { ...row }
      console.log(row)
      this.editCashierDialogVisible = true
    },
    confirmEditCashierDialog() {
      this.$refs.eidtCashierFromRef.validate(async (valid) => {
        if (valid) {
          let data = {
            ...this.editCashierForm,
            time: timeOffset(new Date(this.editCashierForm.time)),
          }

          console.log(data)

          await updateCashier(data)
          this.editCashierDialogVisible = false
          this.getTableData()
        } else {
          return this.$message.error(
            '当前出纳信息不完整,请填写完整之后进行再次尝试'
          )
        }
      })
    },
    async switchInvoceState(row) {
      let data = {
        ...row,
        time: timeOffset(new Date(row.time)),
        hasInvoice: !row.hasInvoice,
      }
      await updateCashier(data)
      this.getTableData()
    },
    onFocus(event) {
      iosOnFocus.call(this,event);
    },
  },
}
</script>

<style lang="scss" scoped>
.el-select {
  width: 100%;
}
</style>
