import request from '@/utils/request'

export function getCashierList (data) {
  return request({
    url: 'api/CashierDetail/GetList',
    method: 'post',
    data
  })
}


export function downCashierList (data) {
  return request({
    url: 'api/CashierDetail/DownloadExcel',
    method: 'post',
    data,
    responseType: 'blob',
  })
}


export function getCashierTypeOptions () {
  return request({
    url: 'api/CashierDetail/GetCashierDetailOptions',
    method: 'get',
  })
}

export function addCashier (data) {
  return request({
    url: 'api/CashierDetail/AddNew',
    method: 'post',
    data
  })
}

export function updateCashier (data) {
  return request({
    url: 'api/CashierDetail/Update',
    method: 'post',
    data
  })
}

export function deleteCashier (id) {
  return request({
    url: 'api/CashierDetail/Delete?id=' + id,
    method: 'get'
  })
}